import React, { useState, useEffect, useRef } from "react";
import images from "../../themes/appImage";
import { Image, Select, Spin } from "antd";
import appconstant from "../../themes/appconstant";
import Textfield from "../../common";
import TimePicker from "../../common/TimePicket";
import DatePicker from "../../common/DatePicker";
import { constantRoutes } from "../../constants/constantRoutes";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import {
  constValues,
  time_dateFormat,
  extentions,
} from "../../constants/constantValues";
import moment from "moment-timezone";
import * as Yup from "yup";
import { toast } from "react-toastify";
import ValidationConstants from "../../themes/validationConstants";
import { beginningSpace } from "../../utils/validator";
import { ValidationFunction } from "../../themes/validationFunctions";
import { DropDownSelect } from "../../common/DropDownSelect";
import {
  createEventAction,
  timezoneAction,
} from "../../redux/actions/eventActions.js";
import BreadCrumb from "../../common/breadCrumb";
import { userAddressAction } from "../../redux/actions/userActions";
import TextAreafield from "../../common/TextAreaField";
import { Option } from "antd/lib/mentions";
import GooglePlacesAutoComplete from "../../common/googlePlacesAutoComplete";
var isSpin = false;
const AddEvent = () => {
  const refs = useRef();
  const [image, setImage] = useState(null);
  const [isSpin, setIsSpin] = useState(false);
  const [eventFor, setEventFor] = useState('paid')
  const [isVisible, setIsVisible] = useState(false);
  const [latlng, setLatlng] = useState({
    lat: 0,
    lng: 0
  });
  const dispatch = useDispatch();
  const locations = useSelector(
    (state) => state.userManagementReducer?.location
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(timezoneAction());
    dispatch(userAddressAction());
  }, []);

  const imageSet = (e) => {
    if (
      e.target.files[0]?.type === extentions.jpeg ||
      e.target.files[0]?.type === extentions.png ||
      e.target.files[0]?.type === extentions.jpg
    ) {
      if (e.target.files[0]?.size < constValues.imageSize) {
        setImage(e.target.files[0]);
      } else {
        toast(ValidationConstants.fileSize);
      }
    } else {
      toast(ValidationConstants.fileExtention);
    }
  };

  const onSubmit = (e) => {
    console.log(e, "CACLALSLASDA");
    const body = new FormData();
    setIsSpin(true);
    let startDate = moment(e?.startDate).format("YYYY-MM-DD");
    let startTime = moment(e.startTime).format("HH:mm:ss");

    let hoursDuration = parseInt(e.duration.split(":")[0]);
    let minuteDuration = e.duration.split(":")[1];
    let START_TIME = moment(`${startDate}T${startTime}`)
      .utc()
      .format("YYYY-MM-DDTHH:mm:ssZ");
    let END_TIME = null;

    if (hoursDuration > 0)
      END_TIME = moment(`${startDate}T${startTime}`)
        .utc()
        .add(hoursDuration, "hours")
        .format("YYYY-MM-DDTHH:mm:ssZ");
    if (minuteDuration > 0)
      END_TIME = moment(`${startDate}T${startTime}`)
        .utc()
        .add(minuteDuration, "minutes")
        .format("YYYY-MM-DDTHH:mm:ssZ");
    let start_Date = moment(startDate).format("YYYY-MM-DD")
    body.append(
      "startDate",
      `${start_Date}T00:00:00.000Z`
    );
    body.append("endDate", END_TIME);
    body.append("startTime", START_TIME);
    body.append("name", e?.name);
    body.append("description", e?.description);
    body.append("image", image);
    body.append("address", e?.address);
    body.append("latitude", latlng.lat);
    body.append("longitude", latlng.lng);
    body.append("visibleTo", eventFor)
    body.append("duration", e?.duration);

    if (image) {
      dispatch(createEventAction(body));
    } else {
      setIsSpin(false);
      toast(ValidationConstants.profileRequired, { toastId: 22 });
    }
    setTimeout(() => {
      setIsSpin(false);
    }, 1500);
  };

  const validate = (value) => {
    const errors = {};
    console.log(
      " value?.duration? value?.duration? value?.duration? value?.duration?",
      value
    );
    if (!value?.duration) {
      errors.duration = ValidationConstants.duration;
    }
    if (
      value?.duration?.split(":")[0] === "00" &&
      value?.duration?.split(":")[1] === "00"
    ) {
      errors.duration = ValidationConstants.durationVALID;
    }
    return errors;
  };

  const validationSchema = Yup.object().shape({
    description: Yup.string()
      .required(ValidationConstants.descriptionRequired)
      .matches(
        beginningSpace,
        ValidationFunction.validField(
          appconstant.description.toLocaleLowerCase()
        )
      )
      .min(3, ValidationFunction.minlength(appconstant.description)),
    name: Yup.string()
      .required(ValidationConstants.eventNameRequired)
      .matches(
        beginningSpace,
        ValidationFunction.validField(appconstant.eventName.toLocaleLowerCase())
      )
      .min(3, ValidationFunction.minlength("Event name")),
    startTime: Yup.string().required(
      ValidationFunction.plzSelect(
        appconstant.eventStartTime.toLocaleLowerCase()
      )
    ),
    startDate: Yup.string().required(
      ValidationFunction.plzSelect(
        appconstant.eventStartDate.toLocaleLowerCase()
      )
    ),
    address: Yup.string()
      .required(ValidationConstants.addressRequired)
      .matches(
        beginningSpace,
        ValidationFunction.validField(appconstant.address.toLocaleLowerCase())
      )
      .min(3, ValidationFunction.minlength(appconstant.address)),
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      endTime: "",
      startDate: "",
      startTime: "",
      description: "",
      address: "",
      duration: "",
    },
    validationSchema,
    onSubmit,
    validate,
  });

  const state = locations?.filter(
    (value) => value?._id === formik.values?.state
  )?.[0]?.name;
  const cities = locations?.filter((value) => {
    if (value?._id === formik.values?.state) {
      return value?.cities;
    }
  })?.[0]?.cities;

  return (
    <div>
      <BreadCrumb
        firstCrumb={{
          link: constantRoutes.eventmanagement,
          pathName: appconstant.eventManagement,
        }}
        currentCrumb={appconstant.addEvent}
      />
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div className="content-e">
          <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
            <h6 className="text-white text-capitalize ps-3">
              {appconstant.addEvent}
            </h6>
          </div>
          <div className="profile-image">
            <Image
              preview={false}
              type="image"
              src={image ? URL.createObjectURL(image) : images.edit}
              className={image && "upload_image"}
            />
            <div className="datacenter-icon">
              <input
                type="image"
                src={images.pluss}
                title={ValidationFunction.clickToImg(
                  image
                    ? appconstant.change
                    : appconstant.add.toLocaleLowerCase()
                )}
                onClick={() => refs.current.click()}
              />
              {console.log(formik.values.startTime)}
              <input
                type="file"
                style={{ display: "none" }}
                ref={refs}
                onChange={imageSet}
                accept={Object.values(extentions)}
                onClick={(e) => (e.target.value = null)}
              />
            </div>
          </div>
          <div className="wrapper_line view_modules view ">
            <form noValidate onSubmit={formik.handleSubmit}>
              <div>
                <div className="form-group">
                  <label>{appconstant.eventName}</label>
                  <Textfield
                    className="form-control"
                    type="text"
                    placeholder={appconstant.eventName}
                    onChange={(text) =>
                      formik.handleChange("name")(text.target.value.trimLeft())
                    }
                    value={formik.values.name}
                    error={formik.errors.name}
                    maxLength={50}
                  />
                </div>
                <div className="form-group">
                  <label>{appconstant.eventStartDate}</label>
                  <DatePicker
                    name="startDate"
                    placeholder={appconstant.eventStartDate}
                    inputReadOnly={true}
                    onChange={(_, value) =>
                      formik.setFieldValue("startDate", value)
                    }
                    disabledDate={(current) =>
                      current && current < moment().subtract(1, "day")
                    }
                    format={time_dateFormat.dateFormat}
                    error={formik.errors.startDate}
                  />
                </div>
                <div className="form-group">
                  <label>{appconstant.eventStartTime}</label>
                  <TimePicker
                    placeholder={appconstant.eventStartTime}
                    inputReadOnly={true}
                    onChange={(o, value) =>
                      formik.setFieldValue("startTime", o?._d)
                    }
                    format={time_dateFormat.timeFormat}
                    error={formik.errors.startTime}
                  />
                </div>
                <div className="form-group">
                  <label>{appconstant.TimeDuration}</label>
                  <TimePicker
                    placeholder={appconstant.TimeDuration}
                    showNow={false}
                    inputReadOnly={true}
                    onChange={(_, value) => {
                      formik.setFieldValue("duration", value);
                    }}
                    format={time_dateFormat.Duration}
                    error={formik.errors.duration}
                  />
                  {console.log(">>>>>>>>>", formik.errors)}
                </div>
                <div className="form-group">
                  <label>{appconstant.eventDescription}</label>
                  <TextAreafield
                    className="textArea-control"
                    type="text"
                    placeholder={appconstant.eventDescription}
                    onChange={formik.handleChange("description")}
                    value={formik.values.description}
                    error={formik.errors.description}
                    maxLength={500}
                  />
                </div>
                <div className="form-group">
                  <label>{appconstant.eventFor}</label>
                  <Select
                    style={{
                      width: 120,
                      color: '#000000'
                    }}
                    name={"type"}
                    value={eventFor}
                    onChange={(e) => setEventFor(e)}
                  >
                    <Option title="" value="guest">
                      Guest
                    </Option>
                    <Option title="" value="paid">
                      Members
                    </Option>
                    <Option title="" value="all">
                      All
                    </Option>
                  </Select>

                </div>
                {/* <div className="form-group">
                  <label>{appconstant.state}</label>
                  <DropDownSelect
                    spaceClass={"input_fields"}
                    placeholder={appconstant.state}
                    data={locations}
                    showSearch
                    onChange={(_, { key }) => formik.handleChange("state")(key)}
                    value={state}
                    error={formik.errors.state}
                    optionFilterProp={"children"}
                  />
                </div>
                <div className="form-group">
                  <label>{appconstant.city}</label>
                  <DropDownSelect
                    spaceClass={"input_fields"}
                    placeholder={appconstant.city}
                    data={cities}
                    error={ValidationFunction.cityState(
                      cities,
                      state,
                      formik.errors.city
                    )}
                    showSearch
                    onChange={(_, { key }) => formik.handleChange("city")(key)}
                    disabled={cities?.length ? false : true}
                    optionFilterProp={"children"}
                  />
                </div> */}
                <div className="form-group">
                  <label>{appconstant.address}</label>
                  <Textfield
                    className="form-control"
                    type="text"
                    placeholder={appconstant.address}
                    onChange={formik.handleChange("address")}
                    value={formik.values.address}
                    error={formik.errors.address}
                    maxLength={1000}
                    onClick={() => setIsVisible(true)}
                  />
                </div>
              </div>
              <div className="up-btn">
                <button disabled={isSpin} type="submit" className="button-list">
                  {isSpin ? <Spin spinning></Spin> : appconstant.add}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <GooglePlacesAutoComplete isVisible={isVisible} setIsVisible={setIsVisible} onPlaceSelected={(place) => {
        let tempAddress = place?.formatted_address.split(',')
        tempAddress.pop();
        tempAddress.pop();
        formik.handleChange("address")(tempAddress.toString())
        setLatlng({
          lat: place?.geometry?.location?.lat(),
          lng: place?.geometry?.location?.lng()
        })
      }} />
    </div>
  );
};
export default AddEvent;
